<template>
  <div class="controle-patrimonial">
    <h1 class="titulo-card fs-4">Controle patrimonial</h1>

      <div class="row gx-md-3">

        <div class="col-12 mb-3">
          <div class="d-grid gap-2 d-md-block">
            <button type="button" v-on:click="novoObjetoHandle()" class="btn btn-success mx-md-2">
              <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>Novo objeto
            </button>
            <button type="button" v-on:click="novaMovimentacaoHandle()" class="btn btn-primary mx-md-2">
              <font-awesome-icon :icon="['fas', 'people-carry']" fixed-width class="me-1"/>Movimentar objeto
            </button>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 px-md-3 mb-3">
          <div class="card card-raised border-start border-primary border-4 text-white- bg-primary- h-100-">
            <div class="card-body px-4">
              <div class="overline text-muted mb-1">Objetos</div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="me-2">
                  <div class="display-6 me-3">-</div>
                </div>
                <div class="d-flex align-items-center text-success">
                  <font-awesome-icon :icon="['fas', 'arrow-up']" fixed-width class="text-success"/>
                  <div class="caption text-success fw-500 me-2">-%</div>
                </div>
              </div>
              <div class="card-text">
                <div class="d-inline-flex align-items-center">
                  <div class="text-xs text-muted mb-1">Mês passado: -</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 px-md-3 mb-3">
          <div class="card card-raised border-start border-primary border-4 text-white- bg-primary- h-100-">
            <div class="card-body px-4">
              <div class="overline text-muted mb-1">Valor dos bens</div>
              <div class="d-flex justify-content-between align-items-center">
                <div class="me-2">
                  <div class="display-6 me-3">-</div>
                </div>
                <div class="d-flex align-items-center text-success">
                  <font-awesome-icon :icon="['fas', 'arrow-up']" fixed-width class="text-success"/>
                  <div class="caption text-success fw-500 me-2">-%</div>
                </div>
              </div>
              <div class="card-text">
                <div class="d-inline-flex align-items-center">
                  <div class="text-xs text-muted mb-1">Mês passado: -</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <div class="me-2">
            <div class="display-6 me-3">ÚLTIMAS MOVIMENTAÇÕES</div>
          </div>
          <div class="card">
            <img class="card-img-top" src="holder.js/100px180/" alt="">
            <div class="card-body">
              <h4 class="card-title">Resumo</h4>
              <p class="card-text">
                <table class="table">
                  <tbody>
                    <tr v-for="mov in movimentacoesRecentes" :key="mov.id">
                      <td scope="row">
                        <span class="text-muted-2">
                          <font-awesome-icon :icon="['fas', 'calendar']" fixed-width/>
                          {{mov.created_at}}
                        </span>
                        <font-awesome-icon :icon="['fas', 'user']" fixed-width class="ms-2"/>
                        {{traduzColaborador(mov.colaborador_id)}}

                        <br>
                        <font-awesome-icon :icon="['fas', 'box']" fixed-width/>
                        {{traduzObjeto(mov.item_id)}}

                        <br>
                        <font-awesome-icon :icon="['fas', 'thumbtack']" fixed-width/>
                        {{traduzMovimentacao(mov.tipo_movimentacao)}}

                        <Tooltip v-if="mov.tipo_movimentacao === 'mpObservacao'" position="top" :tooltipText="mov.observacao" class="d-inline- d-sm-none-">
                          <span class="text-warning cursor-pointer ms-1">
                            <font-awesome-icon :icon="['fas', 'exclamation-circle']" fixed-width/>
                          </span>
                        </Tooltip>

                        <button type="button" v-if="mov.tipo_movimentacao === 'mpObservacao'" v-on:click="visualizaObservacao(mov.observacao)" class="ms-1 btn btn-outline-primary btn-sm">
                          <font-awesome-icon :icon="['fas', 'eye']" fixed-width/>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="table d-none">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Item</th>
                      <th>Local</th>
                      <th>Colaborador</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="mov in movimentacoesRecentes" :key="mov.id">
                      <td scope="row">{{mov.created_at}}</td>
                      <td>{{traduzObjeto(mov.item_id)}}</td>
                      <td>
                        {{traduzMovimentacao(mov.tipo_movimentacao)}}

                        <Tooltip v-if="mov.tipo_movimentacao === 'mpObservacao'" position="top" :tooltipText="mov.observacao" class="d-inline- d-sm-none-">
                          <span class="text-warning cursor-pointer ms-1">
                            <font-awesome-icon :icon="['fas', 'exclamation-circle']" fixed-width/>
                          </span>
                        </Tooltip>

                        <button type="button" v-if="mov.tipo_movimentacao === 'mpObservacao'" v-on:click="visualizaObservacao(mov.observacao)" class="ms-1 btn btn-outline-primary btn-sm">
                          <font-awesome-icon :icon="['fas', 'eye']" fixed-width/>
                        </button>
                      </td>
                      <td>{{traduzColaborador(mov.colaborador_id)}}</td>
                    </tr>
                  </tbody>
                </table>
              </p>
            </div>
          </div>
        </div>

      </div>


      <!-- modal-objeto -->
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-primary btn-lg d-none" id="btn-modal-objeto-abre" data-bs-toggle="modal" data-bs-target="#modalEditaObjeto">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modalEditaObjeto" tabindex="-1" role="dialog" aria-labelledby="modalEditaObjetoId" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Objeto patrimonial</h5>
              <button type="button" id="btn-modal-objeto-fecha" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12 mb-3">
                    <label for="modal-objeto-descricao" class="form-label mb-0">Descrição</label>
                    <input type="text" v-model="modalObjeto.form.descricao" class="form-control" id="modal-objeto-descricao" placeholder="">
                </div>
                <div class="col-12 mb-3">
                    <label for="modal-objeto-numero-de-serie" class="form-label mb-0">Número de série</label>
                    <input type="text" v-model="modalObjeto.form.numeroDeSerie" class="form-control" id="modal-objeto-numero-de-serie" placeholder="">
                </div>
                <div class="col-12 mb-3">
                    <label for="modal-objeto-data-aquisicao" class="form-label mb-0">Data aquisição</label>
                    {{modalObjeto.form.dataAquisicao}}
                    <input type="date" v-model="modalObjeto.form.dataAquisicao" class="form-control" id="modal-objeto-data-aquisicao" placeholder="">
                </div>
                <div class="col-12 mb-3">
                    <label for="modal-objeto-valor-aquisicao" class="form-label mb-0">Valor aquisição</label>
                    <div class="input-group mb-3">
                      <span class="input-group-text">R$</span>
                      <input type="text"
                          id="modal-objeto-valor-aquisicao"
                          v-model="modalObjeto.form.valor"
                          v-on:keyup="modalObjetoChangeValor"
                          v-on:enter="modalObjetoChangeValor"
                          v-on:focus="modalObjetoValorCursorEnd"
                          class="form-control text-end"
                          placeholder="0,00">
                    </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" v-on:click="modalObjetoFecha()" class="btn btn-outline-secondary">Fechar</button>
              <button type="button" v-on:click="modalObjetoSalva()" v-bind:disabled="(!modalObjetoPodeSalvar())" class="btn btn-success">
                <font-awesome-icon :icon="['fas', 'check']" fixed-width class="me-1"/>
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-objeto -->

      <!-- modal-movimentacao -->
      <!-- Button trigger modal -->
      <button type="button" class="btn btn-primary btn-lg d-none" id="btn-modal-movimentacao-abre" data-bs-toggle="modal" data-bs-target="#modalEditaMovimentacao">
        Launch
      </button>

      <!-- Modal -->
      <div class="modal fade" id="modalEditaMovimentacao" tabindex="-1" role="dialog" aria-labelledby="modalEditaMovimentacaoId" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Movimentação de objeto patrimonial</h5>
              <button type="button" id="btn-modal-movimentacao-fecha" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <!-- escolha do objeto -->
              <div v-if="(!modalMovimentacao.objeto)" class="row">
                <div class="col-12 mb-3">
                    <label for="modal-movimentacao-pesquisa" class="form-label mb-0">Procurar</label>
                    <div class="input-group mb-3">
                      <input type="text" v-model="modalMovimentacao.pesquisa" class="form-control" id="modal-movimentacao-descricao" placeholder="Entre com o nome ou SN">
                      <button type="button" v-on:click="modalMovimentacaoPesquisa()" class="btn btn-secondary" aria-label="">
                        <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
                      </button>
                    </div>
                </div>

                <div class="col-12">
                  <table v-if="shared.app.isMobile()" class="table">
                    <thead>
                      <tr>
                        <th>Objeto / Localização</th>
                        <th>Responsável / Última alteração</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="objeto in modalMovimentacao.listaObjetos"
                          :key="objeto.id"
                          v-on:click="modalMovimentacaoEscolheObjeto(objeto)"
                          class="cursor-pointer">
                        <td scope="row">
                          {{objeto.descricao}} - SN: {{objeto.numero_de_serie}}
                          <br>{{objeto.tipo_localizacao}}
                        </td>
                        <td>
                          {{objeto.responsavel_id}}
                          <br>desde: {{objeto.data_aquisicao}}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table v-if="!shared.app.isMobile()" class="table">
                    <thead>
                      <tr>
                        <th>Objeto</th>
                        <th>Localização</th>
                        <th>Responsável</th>
                        <th>Última alteração</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="objeto in modalMovimentacao.listaObjetos"
                          :key="objeto.id"
                          v-on:click="modalMovimentacaoEscolheObjeto(objeto)"
                          class="cursor-pointer">
                        <td scope="row">{{nomeObjetoComSn(objeto)}}</td>
                        <td>{{modalMovimentacaoTraduzLocalizacao(objeto)}}</td>
                        <td>{{traduzColaborador(objeto.responsavel_id)}}</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="form-group">
                    <Paginador v-model="modalMovimentacao.paginador" @change-paginacao="modalMovimentacaoOnChangePaginacao"/>
                  </div>
                </div>

              </div>
              <!-- escolha do objeto -->

              <!-- movimentação do objeto -->
              <div v-if="(modalMovimentacao.objeto)" class="row">
                <div class="col-12 mb-3">
                  <button type="button"
                      v-on:click="modalMovimentacaoEscolheObjeto(undefined)"
                      class="btn btn-outline-primary">
                    <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Retornar
                  </button>
                </div>

                <div class="col-12">
                  <!-- Nav tabs -->
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" v-on:click="modalMovimentacao.guiaAtiva = 'mov'" id="painel-movimentar-objeto-tab" data-bs-toggle="tab" data-bs-target="#painel-movimentar-objeto" type="button" role="tab" aria-controls="painel-movimentar-objeto" aria-selected="true">
                        Movimentar objeto
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" v-on:click="modalMovimentacao.guiaAtiva = 'anotacao'" id="painel-anotacao-tab" data-bs-toggle="tab" data-bs-target="#painel-anotacao" type="button" role="tab" aria-controls="painel-anotacao" aria-selected="false">
                        Adicionar anotação
                      </button>
                    </li>
                  </ul>

                  <!-- Tab panes -->
                  <div class="tab-content">
                    <div class="tab-pane active" id="painel-movimentar-objeto" role="tabpanel" aria-labelledby="painel-movimentar-objeto-tab">
                      <label class="custom-control custom-radio cursor-pointer ms-1 me-3">
                        <input type="radio" v-model="modalMovimentacao.form.tipoMovimentacao" name="radio-tipo-mov" value="mpEstoque" class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description ps-1">Estoque</span>
                      </label>
                      <label class="custom-control custom-radio cursor-pointer me-3">
                        <input type="radio" v-model="modalMovimentacao.form.tipoMovimentacao" name="radio-tipo-mov" value="mpTransito" class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description ps-1">Em trânsito</span>
                      </label>
                      <label class="custom-control custom-radio cursor-pointer me-3">
                        <input type="radio" v-model="modalMovimentacao.form.tipoMovimentacao" name="radio-tipo-mov" value="mpCliente" class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description ps-1">Cliente</span>
                      </label>
                      <label class="custom-control custom-radio cursor-pointer me-3">
                        <input type="radio" v-model="modalMovimentacao.form.tipoMovimentacao" name="radio-tipo-mov" value="mpAssinaturaIsp" class="custom-control-input">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description ps-1">Assinatura</span>
                      </label>

                      <div v-if="modalMovimentacaoExigeResponsavel()" class="form-group">
                        <label for="painel-movimentar-objeto-responsavel" class="form-label">Responsável</label>
                        <select v-model="modalMovimentacao.form.colaboradorID" class="form-select" name="painel-movimentar-objeto-responsavel">
                          <option v-for="colaborador in colaboradores" :key="colaborador.id" v-bind:value="colaborador.id">{{colaborador.apelido}}</option>
                        </select>
                      </div>

                      <div v-if="modalMovimentacaoExigeCliente()" class="form-group">
                        <label for="painel-movimentar-objeto-cliente" class="form-label">Cliente</label>
                        <div v-if="(!modalMovimentacao.form.clienteSelecionado)" class="input-group mb-3">
                          <input type="text" v-model="modalMovimentacao.form.pesquisaCliente" class="form-control" name="painel-movimentar-objeto-cliente">
                          <button type="button" v-on:click="modalMovimentacaoPesquisaCliente()" class="btn btn-outline-primary" aria-label="">
                            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
                          </button>
                        </div>

                        <div v-if="(modalMovimentacao.form.clienteSelecionado)" class="input-group mb-3">
                          <input type="text" v-model="modalMovimentacao.form.clienteSelecionado.razao_social" class="form-control" name="painel-movimentar-objeto-cliente" disabled>
                          <button type="button" v-on:click="modalMovimentacaoLimpaCliente()" class="btn btn-outline-danger" aria-label="">
                            <font-awesome-icon :icon="['fas', 'times']" fixed-width class="me-1"/>
                          </button>
                        </div>
                      </div>

                      <div v-if="modalMovimentacaoExigeAssinatura()" class="form-group">
                        <label for="painel-movimentar-objeto-assinatura" class="form-label">Assinatura</label>
                      </div>
                    </div>

                    <div class="tab-pane" id="painel-anotacao" role="tabpanel" aria-labelledby="painel-anotacao-tab">
                      <textarea v-model="modalMovimentacao.form.anotacao" rows="4" class="form-control"></textarea>
                    </div>
                  </div>
                  <!-- Tab panes -->

                </div>
              </div>
              <!-- movimentação do objeto -->
            </div>
            <div class="modal-footer">
              <button type="button"
                  v-on:click="modalMovimentacaoFecha()"
                  class="btn btn-outline-secondary">
                Fechar
              </button>
              <button type="button"
                  v-on:click="modalMovimentacaoSalva()"
                  v-if="(modalMovimentacao.objeto)"
                  v-bind:disabled="(!modalMovimentacaoPodeSalvar())"
                  class="btn btn-success">
                <font-awesome-icon :icon="['fas', 'plus']" fixed-width class="me-1"/>
                Executar
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- modal-movimentacao -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import Tooltip from "@/components/Tooltip.vue";
import api from '../modules/api'
import dialogo from '../modules/dialogo'
import moeda from '../modules/moeda'
import loading from '../modules/loading'
import clone from '../modules/clone'
import shared from '../modules/shared'
import model from '../model'
import pesquisaCliente from '@/modules/pesquisa-cliente'

export default {
  name: 'ControlePatrimonial',
  components: {
    Paginador,
    Tooltip,
  },
  data() {
    return {
      colaboradores: [],
      movimentacoesRecentes: [],
      movimentacoesRecentesObjetos: [],
      movimentacoesRecentesClientes: [],
      modalObjeto: {
        objeto: undefined,
        form: {
          descricao: '',
          numeroDeSerie: '',
          dataAquisicao: '',
          valor: '0,00',
        }
      },

      modalMovimentacao: {
        objeto: undefined,
        pesquisa: '',
        listaObjetos: [],
        listaClientes: [],
        /** @type {model.paginador} */
        paginador: clone(model.paginador),
        guiaAtiva: '',
        form: {
          clienteSelecionado: undefined,
          pesquisaCliente: '',
          tipoMovimentacao: '',
          anotacao: '',
          colaboradorID: 0,
          clienteID: 0,
          assinaturaIspID: 0,
        }
      }
    }
  },

  methods: {
    carregaColaboradores() {
      loading(true);
      api.get('/colaboradores?ativo=1&paginate.cancel').then(res => {
        loading(false);

        this.colaboradores = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    novoObjetoHandle() {
      this.modalObjetoReset();
      this.modalObjetoAbre();
    },

    novaMovimentacaoHandle() {
      this.modalMovimentacaoReset();
      this.modalMovimentacaoAbre();
    },

    listaMovimentacoesRecentes() {
      api.get('/patrimonio/itens/movimentacoes?orderBy[id]=desc').then(res => {
        this.movimentacoesRecentes = res.data.items;
        this.movimentacoesRecentesObjetos = res.data.objetos;
      });
    },

    traduzObjeto(id) {
      const objeto = this.movimentacoesRecentesObjetos.find(x => x.id === id);

      if (objeto) {
        return this.nomeObjetoComSn(objeto);
      }

      return '-';
    },

    nomeObjetoComSn(objeto) {
      if (objeto.numero_de_serie) {
        return `${objeto.descricao} [${objeto.numero_de_serie}]`;
      }
      return objeto.descricao;
    },

    traduzColaborador(id) {
      const colaborador = this.colaboradores.find(x => x.id === id);

      if (colaborador) {
        return colaborador.apelido;
      }

      return '-';
    },

    traduzMovimentacao(tipoMov) {
      switch (tipoMov) {
        case 'mpEstoque':
          return 'Estocado';

        case 'mpTransito':
          return 'Em trânsito';

        case 'mpCliente':
          return 'Cliente';

        case 'mpAssinaturaIsp':
          return 'Atrelado à assinatura';

        case 'mpObservacao':
          return 'Observação';

      }

      return '-';
    },

    traduzLocalizacao(objeto, clientes) {
      switch (objeto.tipo_localizacao) {
        case 'tlEstoque':
          return 'Estoque';

        case 'tlTransito':
          return 'Em trânsito';

        case 'tlCliente': {
          const nomeCliente = this.traduzCliente(clientes, objeto.cliente_id);
          return `Cliente [${nomeCliente}]`;
        }

        case 'tlAssinaturaIsp':
          return 'Atrelado à assinatura';
      }

      return '-';
    },

    traduzCliente(clientes, id) {
      const cliente = clientes.find(x => x.id === id);

      if (cliente) {
        return cliente.razao_social;
      }

      return '-';
    },

    visualizaObservacao(mensagem) {
      dialogo.info(mensagem, 'Observação');
    },

    //////////////////////////////
    // modal-objeto
    modalObjetoAbre() {
      document.getElementById('btn-modal-objeto-abre').click();
    },

    modalObjetoFecha() {
      document.getElementById('btn-modal-objeto-fecha').click();
    },

    modalObjetoPodeSalvar() {
      const form = this.modalObjeto.form;

      switch (true) {
        case !form.descricao:
        case !form.dataAquisicao:
          return false;
      }

      return true;
    },

    modalObjetoSalva() {
      const form = this.modalObjeto.form;
      const valor = moeda.fromString(form.valor);

      const sendData = {
        descricao: form.descricao,
        numero_de_serie: form.numeroDeSerie,
        data_aquisicao: form.dataAquisicao,
        valor_aquisicao: valor,
      };

      loading(true);
      api.post('/patrimonio/itens', sendData).then(() => {
        loading(false);
        dialogo.alerta('O item foi cadastrado com sucesso');
        this.modalObjetoFecha();
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modalObjetoReset() {
      this.modalObjeto.objeto = undefined;
      this.modalObjeto.form.descricao = '';
      this.modalObjeto.form.numeroDeSerie = '';
      this.modalObjeto.form.dataAquisicao = shared.date.todayMysql();
      this.modalObjeto.form.valor = '0,00';
    },

    modalObjetoChangeValor() {
      const valor = moeda.fromString(this.modalObjeto.form.valor);

      this.modalObjeto.form.valor = moeda.toStringBrl(valor);
    },

    modalObjetoValorCursorEnd() {
      const myInput = document.getElementById('modal-objeto-valor-aquisicao');
      const pos = this.modalObjeto.form.valor.length;

      myInput.setSelectionRange(pos, pos);

      setTimeout(function() {
        myInput.setSelectionRange(pos, pos);
      }, 100);

      setTimeout(function() {
        myInput.setSelectionRange(pos, pos);
      }, 300);

      setTimeout(function() {
        myInput.setSelectionRange(pos, pos);
      }, 500);
    },
    // modal-objeto
    //////////////////////////////

    //////////////////////////////
    // modal-movimentacao
    modalMovimentacaoAbre() {
      document.getElementById('btn-modal-movimentacao-abre').click();
      this.modalMovimentacaoPesquisa();
    },

    modalMovimentacaoFecha() {
      document.getElementById('btn-modal-movimentacao-fecha').click();
    },

    modalMovimentacaoPodeSalvar() {
      const modal = this.modalMovimentacao;
      const form = this.modalMovimentacao.form;
      const tipoMov = form.tipoMovimentacao;

      switch (true) {
        case (!modal.guiaAtiva):
        case (modal.guiaAtiva === 'mov' && !tipoMov):
        case (modal.guiaAtiva === 'mov' && this.modalMovimentacaoExigeResponsavel() && !form.colaboradorID):
        case (modal.guiaAtiva === 'mov' && this.modalMovimentacaoExigeCliente() && !form.clienteID):
        case (modal.guiaAtiva === 'mov' && this.modalMovimentacaoExigeAssinatura() && !form.assinaturaIspID):
        case (modal.guiaAtiva === 'anotacao' && !form.anotacao):
          return false;
      }

      return true;
    },

    modalMovimentacaoReset() {
      this.modalMovimentacao.objeto = undefined;
      this.modalMovimentacao.pesquisa = '';
      this.modalMovimentacao.guiaAtiva = '';
      this.modalMovimentacao.form.clienteSelecionado = undefined;
      this.modalMovimentacao.form.pesquisaCliente = '';
      this.modalMovimentacao.form.tipoMovimentacao = '';
      this.modalMovimentacao.form.anotacao = '';
      this.modalMovimentacao.form.colaboradorID = 0;
      this.modalMovimentacao.form.clienteID = 0;
      this.modalMovimentacao.form.assinaturaIspID = 0;
    },

    modalMovimentacaoPesquisa() {
      loading(true);
      api.get('/patrimonio/itens').then(res => {
        loading(false);
        this.modalMovimentacao.listaObjetos = res.data.items;
        this.modalMovimentacao.listaClientes = res.data.clientes;
        this.modalMovimentacao.paginador.pagina = res.data.page;
        this.modalMovimentacao.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    modalMovimentacaoOnChangePaginacao() {
      this.handlePesquisa();
    },

    modalMovimentacaoEscolheObjeto(objeto) {
      this.modalMovimentacao.objeto = objeto;
      this.modalMovimentacao.guiaAtiva = 'mov';
      this.modalMovimentacao.form.tipoMovimentacao = '';
    },

    modalMovimentacaoExigeResponsavel() {
      const destinos = ['mpTransito'];

      return destinos.includes(this.modalMovimentacao.form.tipoMovimentacao);
    },

    modalMovimentacaoExigeCliente() {
      return (this.modalMovimentacao.form.tipoMovimentacao === 'mpCliente');
    },

    modalMovimentacaoExigeAssinatura() {
      return (this.modalMovimentacao.form.tipoMovimentacao === 'mpAssinaturaIsp');
    },

    modalMovimentacaoSalva() {
      const itemID = this.modalMovimentacao.objeto.id;
      const form = this.modalMovimentacao.form;

      const sendData = {
        item_id: itemID,
        tipo_movimentacao: form.tipoMovimentacao,
        cliente_id: form.clienteID,
        assinatura_isp_id: form.assinaturaIspID,
        colaborador_id: form.colaboradorID,
        observacao: form.anotacao,
      };

      if (this.modalMovimentacao.guiaAtiva === 'anotacao') {
        sendData.tipo_movimentacao = 'mpObservacao';
      }

      api.post(`/patrimonio/itens/${itemID}/movimentacoes`, sendData).then(() => {
        dialogo.alerta('Movimentação feita com sucesso.');
        this.modalMovimentacao.objeto = undefined;
        this.modalMovimentacaoPesquisa();
        this.listaMovimentacoesRecentes();
      }).catch(error => {
        dialogo.erroHttp(error);
      });
    },

    modalMovimentacaoPesquisaCliente() {
      pesquisaCliente().then(res => {
        this.modalMovimentacao.form.clienteSelecionado = clone(res);
        this.modalMovimentacao.form.clienteID = res.id;
      });
    },

    modalMovimentacaoLimpaCliente() {
      this.modalMovimentacao.form.clienteSelecionado = undefined;
      this.modalMovimentacao.form.clienteID = 0;
    },

    modalMovimentacaoTraduzLocalizacao(objeto) {
      return this.traduzLocalizacao(objeto, this.modalMovimentacao.listaClientes);
    },
    // modal-movimentacao
    //////////////////////////////
  },

  computed: {
    shared() {
      return shared
    },
  },

  created() {
    this.carregaColaboradores();
    this.listaMovimentacoesRecentes();
  },

  destroyed() {
  },
}
</script>

<style>
.card-disabled {
  opacity: 0.5;
}

.card-raised {
    border: none;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    font-family: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.display-6 {
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    font-size: 1.25rem;
}

.overline {
    font-size: 0.75rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.1666666667em;
    text-decoration: none;
    text-transform: uppercase;
}

</style>